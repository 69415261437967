import React, { useState } from 'react';
import Fetch from '../fetch';

const authModal = (props) => {
    const [show, setShow] = useState(props.open || false);
    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const type = props.type || "primary"
    const text = props.text || "Login"

    const search = location.search.substring(1);

    const query = (search.length) ? JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) }) : null

    async function onLogin() {
        let jsonReponse;
        if (query?.aatAction) {
            jsonReponse = query;
            query.aatEmail = email;
            query.aatPassword = password;
            const authRes = await Fetch.post('auth/oauth',query);
            window.opener.onFinish(authRes);
        } else {
            const url = props.url || 'auth/login';
            jsonReponse = await Fetch.post(url, { email, password })
            jsonReponse.email = email;   
            props.onLogin(jsonReponse)
        }
        setShow(false);
    }

    return (
        <>
            {!show && <button type="button" className={"btn btn-" + type} data-mdb-modal-init data-mdb-target="#exampleModal" onClick={handleShow}>
                {props.user ? 'Welcome back' : text}
            </button>}

            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Login</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">

                                <form className="mb-md-5 mt-md-4 pb-5">

                                    <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                                    <p className="text-white-50 mb-5">Please enter your login and password!</p>

                                    <div className="form-outline form-white mb-4">
                                        <input type="email" id="typeEmailX" className="form-control form-control-lg" onChange={(e) => setEmail(e.target.value)} />
                                        <label className="form-label">Email</label>
                                    </div>

                                    <div className="form-outline form-white mb-4">
                                        <input type="password" id="typePasswordX" className="form-control form-control-lg" onChange={(e) => setPassword(e.target.value)} />
                                        <label className="form-label">Password</label>
                                    </div>
                                    <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal" onClick={onLogin}>
                                        Login
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default authModal