import React, { useState } from 'react';

const ConfirmationModal = (props) => {
    const [show, setShow] = useState(true);
    const [password, setTarget] = useState(false);
    const isProd = props.pipeline?.env?.includes("prod");

    const handleClose = () => {
        props.onCancel()
        setShow(false);
    }

    async function onRun() {
        props.onConfirm(password)
        setShow(false);
    }

    return (
        <>
            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog  ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" onClick={handleClose} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-5 text-center">

                                <form className="mb-md-5 mt-md-4 pb-5">

                                    <h5 className="fw-bold mb-2 text-uppercase">Are you sure you want to run</h5>
                                    <pre>
                                        {JSON.stringify(props.pipeline, null, 4)}
                                    </pre>
                                    {isProd ? (<>
                                        <div className="form-outline form-white mb-4">
                                            <label className="form-label"> Please re-enter the target env</label>
                                            <input className="form-control form-control-lg" onChange={(e) => setTarget(e.target.value)} />
                                        </div>

                                    </>) : null}
                                    <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal" onClick={onRun}>
                                        Confirm (Run)
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ConfirmationModal