import React, { useState, useEffect } from 'react';
import Fetch from '../fetch';
const C = require('../config');

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const SimplePopup = (props) => {
    const [isOpen, setIsOpen] = useState(props.open || false);
    const [popupWindow, setPopupWindow] = useState(null);

    const openPopupWindow = () => {
        window.onFinish = (authRes) => onLogin(authRes)
        const width = 800;
        const height = 750;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const newWindow = window.open(props.url, 'popupWindow', `width=${width},height=${height},top=${top},left=${left}`);
        setPopupWindow(newWindow);
        setIsOpen(true);
    };

    const onLogin = (data) => {
        closePopupWindow();
        props.onComplete(data); // Llama a la función de callback aquí
    }

    const closePopupWindow = () => {
        if (popupWindow && !popupWindow.closed) {
            popupWindow.close();
        }
        setPopupWindow(null);
        setIsOpen(false);
    };

    useEffect(() => {
        // Close the popup window when the component unmounts
        return () => {
            if (popupWindow) {
                popupWindow.close();
            }
        };
    }, [popupWindow]);

    useEffect(() => {
        if (props.open) openPopupWindow();
    }, [])

    return null;
};

const OAuth = () => {
    const [loginUrl, setLoginUrl] = useState(null);
    const [currentUser, setCurrentUser] = useState(null); // Estado para el usuario actual
    const [data, setData] = useState();

    const onRequest = async (endpoint) => {
        const requestJson = await Fetch.get(endpoint);
        if (requestJson.redirect) {
            setLoginUrl(requestJson.redirect);
        } else {
            setData(requestJson);
        }
    };

    function onLogout() {
        sessionStorage.removeItem(C.authSessionName);
        setCurrentUser(null);
        setLoginUrl(null)
    }

    const onComplete = (res) => {
        if (res.jwt) {
            const currentUserData = parseJwt(res.jwt);
            currentUserData.token = res.jwt;
            sessionStorage.setItem(C.authSessionName, JSON.stringify(currentUserData));
            setCurrentUser(currentUserData);
        }
        if (res.data) {
            setData(res);
        }
    };

    useEffect(() => {
        const currentUserData = JSON.parse(sessionStorage.getItem(C.authSessionName));
        if (currentUserData) setCurrentUser(currentUserData);
    }, []);

    return (
        <>
            {currentUser?.userName ? (<p>Welcome {currentUser.userName} <button type="button" className="btn btn-link" onClick={onLogout}>logout</button> </p>) : (<p>Try endpoints (without authentication)</p>)}
            <ul className="list-group">
                <li className="list-group-item">
                    <button type="button" className="btn btn-link" onClick={() => onRequest('auth/me')}>GET /me</button>
                </li>
                <li className="list-group-item">
                    <button type="button" className="btn btn-link" onClick={() => onRequest('api/permissions')}>GET /permissions</button>
                </li>
                <li className="list-group-item">
                    <button type="button" className="btn btn-link" onClick={() => onRequest('api/apps')}>GET /apps</button>
                </li>
                <li className="list-group-item">
                    <button type="button" className="btn btn-link" onClick={() => onRequest('api/applications')}>GET /applications (Unauthorized)</button>
                </li>
            </ul>
            {loginUrl && <SimplePopup url={loginUrl} open={true} onClick={onRequest} onComplete={onComplete} />}
            {data && <pre style={{ margin: '2%', background: 'whitesmoke', padding: '1%' }}>
                {JSON.stringify(data, null, 4)}
            </pre>}
        </>
    )
}

export default OAuth