import React, { useState, useEffect, useRef } from 'react';
import Fetch from '../fetch';
import Select from 'react-select';
import Papa from "papaparse";
import { CSVLink } from 'react-csv'
import { ColorRing } from "react-loader-spinner";
const C = require('../config');

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const endpointMapper = {
    appId: 'apps',
    accountId: 'accounts',
    platformId: 'platforms',
    networkId: 'networks',
    countryId: 'countries',
    deviceId: 'devices',
    formatId: 'formats',
    bannerSizeId: 'banner-sizes',
    placementId: 'placements',
    languageId: 'languages',
    sdkId: 'sdk-versions',
    consentType: null,
    consentValue: null,
    networkKeyId: 'network-keys',
    appVersionId: null
};

// helpers
const Checkbox = ({ label, value, onChange, disabled, array }) => {
    const arrCheck = (array) ? array.includes(value) : value;
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={array ? arrCheck : undefined} onChange={onChange} disabled={disabled} />
            {' ' + label}
        </label>
    );
};
const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
function getColorFromPercentage(percentage) {
    // Ensure percentage is within bounds
    percentage = Math.max(-100, Math.min(100, percentage));
    // Convert percentage to a hue value between 0 (red) and 120 (green)
    // -100% maps to 0 (red), 0% maps to 60 (yellow), 100% maps to 120 (green)
    const hue = (percentage + 100) * 0.6;
    // Use a saturation and lightness of 100% for vivid colors
    return `hsl(${hue}, 100%, 50%)`;
}

const Stats = () => {
    const [apiQuery, setApiQuery] = useState(null);
    const [dimensions, setDimensions] = useState([]);
    const [kpis, setKpis] = useState([]);
    const [selectedDim, setSelectedDim] = useState(['accountId']);
    const [filteredDim, setFilteredDim] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [selectedSource, setSelectedSource] = useState([{ content: "revenue", types: ['revenues'] }]);
    const [data, setData] = useState({});
    const [tableRows, setTableRows] = useState([]);
    const [tableValues, setTableValues] = useState([]);
    const [parsedData, setParsedData] = useState([]);
    const [dateAsColumns, setDateAsColumns] = useState(true);
    const [processData, setProcessData] = useState(false);
    const [showAdvance, setShowAdvance] = useState(false);
    const [processedOption, setProcessedOption] = useState(false);
    const [JSONFormat, setJSONFormat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMCM, setShowMCM] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [getBD, setGetBD] = useState(false);
    const [bdAccountList, setBdAccountList] = useState([]);
    const [runDisabled, setRunDisabled] = useState(false);
    const [showId, setShowId] = useState(false);
    const [processedNameFilter, setProcessedNameFilter] = useState(null);
    const [JSONRaw, setJSONRaw] = useState(null);
    const [statisticsApi, setsStatisticsApi] = useState(false);

    const csvLink = useRef() // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

    async function fetchConstants() {
        const constants = await Fetch.get(`${C.projects.dashboard.test.backend + '/constants'}`);
        setDimensions(constants.stats.statistics.tabs);
        setKpis(constants.stats.statistics.filter);
        const newData = { ...data };
        newData.consentType = constants.consentTypes;
        newData.consentValue = constants.consentValues;
        setData(newData);
    }

    async function fetchStats() {
        setLoading(true);

        const statsResult = await Fetch.get(apiQuery);
        const isCSVFormat = apiQuery.includes('download-statistics')
        // parse CSV file
        if (isCSVFormat) {
            Papa.parse(statsResult, {
                complete: function (results) {
                    const rowsArray = [];
                    const valuesArray = [];
                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        rowsArray.push(Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });
                    // Parsed Data Response in array format
                    setParsedData(results.data);
                    // Filtered Column Names
                    setTableRows(rowsArray[0]);
                    // Filtered Values
                    setTableValues(valuesArray);
                },
            });
        } else {
            // parse JSON headers
            const headers = [];
            // dimension columns
            headers.push('Id');
            statsResult.reply.data.series[0].dim.forEach(d => headers.push(d));
            statsResult.reply.time.forEach((row) => {
                // dates columns
                headers.push(row)
            });
            // Rows
            const rows = [];
            statsResult.reply.data.series.forEach((row) => {
                // dates columns
                const line = [];
                line.push(row.id, row.label);
                row.total.forEach(r => {
                    line.push(r);
                })
                rows.push(line);
            });
            // totals
            const totalRow = ['Totals', null];
            statsResult.reply.data.total.forEach((t) => totalRow.push(t));
            rows.push(totalRow);
            // sets
            setTableRows(headers)
            setTableValues(rows)
        }
        setLoading(false)
    }

    async function fetchStatsAPI() {
        if (data.accountId) {
            setLoading(true);
            // generate visibleAccount arr []
            const idArr = data.accountId.map((a => a.id)).join();
            const extractedParams = Object.fromEntries(new URLSearchParams(apiQuery.split('?')[1]));
            extractedParams.visibleAccounts = idArr;
            extractedParams.statistics = selectedSource[0].content;
            const statsResult = await Fetch.post('rawstats', extractedParams);
            setJSONRaw(statsResult);
            setLoading(false)
        }
    }

    async function getCsv() {
        csvLink.current.link.click()
    }

    useEffect(() => {
        fetchConstants();
    }, [])

    useEffect(() => {
        load();
    }, [data])

    useEffect(() => {
        if (statisticsApi && !data.accountId) {
            setAndfilter('accountId');
        }
    }, [statisticsApi])

    useEffect(() => {
        fetchBDAndReload();
    }, [getBD])

    useEffect(() => {
        createQuery();
    }, [selectedDim, selectedFilter, selectedSource, dateAsColumns, JSONFormat, startDate, endDate, bdAccountList, getBD, showId])

    async function fetchBDAndReload() {
        if (getBD) {
            let a = new Date();
            const newData = new Date(a.setMonth(a.getMonth() - 14))
            const month = (newData.getMonth() < 10) ? `0${newData.getMonth()}` : newData.getMonth();
            const bdAccounts = await Fetch.get(`${C.projects.dashboard.test.backend + '/accounts/revenueDates?from=' + newData.getFullYear() + '-' + month + '&techProvider=false&type=revenueStart&number=1000'}`);
            const bdAccountIdArr = [];
            bdAccounts.map(ac => bdAccountIdArr.push(ac.accountId));
            setBdAccountList(bdAccountIdArr);
        }
    }

    function createQuery() {
        let finalQuery = 'https://dashboard-test-middleware.aatkit.com/';
        finalQuery += JSONFormat ? 'statistics?' : 'download-statistics?';
        finalQuery += 'selector=' + selectedDim.toString() + ',eventDay';
        finalQuery += '&dateAsColumns=' + dateAsColumns;
        selectedFilter.forEach(element => {
            finalQuery += '&' + element.dim + '=' + element.value;
        });
        if (showId) finalQuery += '&userSettings=true'
        finalQuery += '&from=' + startDate + '&to=' + endDate;
        let finalSources = [];
        if (selectedSource.length) {
            selectedSource.forEach(el => {
                el.types.forEach(e => {
                    if (!finalSources.includes(e)) {
                        finalSources.push(e);
                    }
                })
            })
        }
        finalSources.forEach(element => {
            finalQuery += '&' + element + '=true';
        });
        const uniqueAccountSelected = selectedFilter.some(a=> a.dim === "accountId");
        if (getBD && bdAccountList.length && !uniqueAccountSelected) {
            finalQuery += '&accountId=' + bdAccountList.toString();
        }
        if (finalSources.includes('user')) {
            let invalidUserConf = false;
            selectedDim.forEach(d => {
                if (!['appId', 'platformId', 'countryId', 'deviceId'].includes(d)) invalidUserConf = true
            })
            if (invalidUserConf) {
                window.alert('Your selected configuration is not valid, Users information is only valid with the following dimensions: "App, Country, Platform, Device"')
                setRunDisabled(true)
            }
        } else {
            setRunDisabled(false)
        }
        setApiQuery(finalQuery);
    }

    async function AddSelector(dim) {
        if (selectedDim.includes(dim)) {
            setSelectedDim(selectedDim.filter(item => item !== dim))
        } else {
            setSelectedDim(selectedDim => [...selectedDim, dim])
        }
    }

    async function AddFilter(dim) {
        if (filteredDim.includes(dim)) {
            setFilteredDim(filteredDim.filter(item => item !== dim))
        } else {
            setFilteredDim(filteredDim => [...filteredDim, dim])
        }
    }

    async function AddSelectetdFilter(value, dim) {
        const finalObject = { value: value.id, dim }
        if (selectedFilter.some(e => e.dim === dim)) {
            setSelectedFilter(selectedFilter.filter(item => item.dim !== dim))
        } else {
            setSelectedFilter(selectedFilter => [...selectedFilter, finalObject])
        }
    }

    async function AddSources(item) {
        const finalObject = { content: item.content, types: item.statsType }
        if (selectedSource.some(e => e.content === item.content)) {
            setSelectedSource(selectedFilter.filter(item => item.content !== item.content))
        } else {
            setSelectedSource(selectedFilter => [...selectedFilter, finalObject])
        }
    }

    async function setAndfilter(dim) {
        if (dim.includes('Id')) {
            let endpoint = endpointMapper[dim];
            const info = await Fetch.get(`${C.projects.dashboard.test.backend + '/' + endpoint}`);
            const newData = { ...data };
            newData[dim] = info;
            setData(newData);
        }
    }

    async function getInfoColumn(dateString, accountId) {
        if (showId) {
            const dateSplit = dateString.split('.');
            const finalDate = `20${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
            let finalUrl = C.projects.dashboard.test.backend + '/dashboard/table?';
            finalUrl += 'from=' + finalDate + '&to=' + finalDate;
            finalUrl += '&type=table&selector[]=formatId&selector[]=networkId&selector[]=countryId';
            finalUrl += '&accountId=' + accountId.replace('[', '').replace(']', '');
            const extraInfo = await Fetch.get(finalUrl);
            const metrics = extraInfo.countryId.request.query.metric;
        }
        else {
            console.error('No Id column');
        }
    }

    function renderBlock(data) {
        if (!data) return;
        // extract first two rows (title and dim)
        let subarray = data.slice(2);

        if (processedNameFilter) {
            subarray = subarray.filter(a => a[0].includes(processedNameFilter))
        }
        // sortedBlock
        const sortedArr = subarray.filter(a => a.filter(Number).length && a[0].toLowerCase() !== 'infinity').sort((a, b) => {
            if (a.filter(Number).length > 0 && b.filter(Number).length > 0) {
                // filter to get just numbers
                const filteredArrA = a.slice(1, -1).filter(Number);
                if (filteredArrA.length) {
                    // sum A arr
                    const sumA = filteredArrA.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    // filter to get just numbers
                    const filteredArrB = b.filter(Number);
                    // sum A arr
                    const sumB = filteredArrB.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    return sumB - sumA;
                }
            }
        });

        const columnTotals = ['Column Totals'];
        sortedArr.forEach(e => {
            for (let i = 1; i < sortedArr[0].length - 1; i++) {
                if (columnTotals[i] == undefined) columnTotals.push(0);
                if (isNumeric(e[i])) {
                    columnTotals[i] = parseFloat(columnTotals[i] + parseFloat(e[i]));
                }
            }
        })
        columnTotals.push('')
        sortedArr.unshift(data[0], data[1], columnTotals)

        return <div>
            <blockquote style={{ fontSize: '80%' }}>* To calculate Totals, TechProvider1 (GAM Only) is excluded</blockquote>
            <table className='table tbl'>
                <tbody>
                    {sortedArr.map((value, index) => { //Rows
                        let average = 0;
                        let titleRow = (value.length > 1 && data[index - 1] && data[index - 1].length < 2);
                        // calculate avg
                        if (value.length > 2 && value && value.filter(Number).length > 0) {
                            const filteredArr = value.filter(Number);
                            average = filteredArr.map(el => parseFloat(el)).reduce((a, b) => a + b) / filteredArr.length;
                        }
                        if (value.length < 2) return <tr><td><h4>{value[0].split('->')[1]}</h4></td></tr>;
                        //skipping GAM/MCM
                        if (!showMCM && value[0].includes('TechProvider1')) return;
                        const totalsColumn = (value[0] === 'Column Totals');

                        let total = 0;

                        return (
                            <tr key={index} className={titleRow || totalsColumn ? 'title' : ''}>
                                {value.map((val, i) => { //Columns
                                    let bg = 'none';
                                    let finalValue = val
                                    if (value.length > 2) {
                                        const delta = 100 * ((val - average) / Math.abs(average));
                                        if (isNumeric(val)) {
                                            bg = getColorFromPercentage(delta)
                                            finalValue = parseFloat(val).toLocaleString();
                                            total += parseFloat(val);
                                        }
                                    }
                                    if (isNumeric(val)) {
                                        // filter per date
                                        return <td className='test' style={{ backgroundColor: bg }} key={value[0] + i + total} onClick={() => getInfoColumn(sortedArr[1][i], value[1])}>{finalValue}</td>;
                                    } else {
                                        // filter per account
                                        return <td className='test' key={value[0] + i + total}>{finalValue}</td>;
                                    }
                                })}
                                {!titleRow && total > 0 ? <td className='test'>{total.toLocaleString()}</td> : ''}
                                {titleRow ? <td className='test'>Row Totals</td> : ''}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    }

    function analyzeCSVData() {
        const splitIndexes = [];
        let accountIdColumn = false;
        tableValues.forEach((row, i) => {
            if (row[0].includes('Data by')) {
                const label = row[0].split('->')[1].trim();
                splitIndexes.push({ value: i, label: label })
            }
            if (row.includes('Account-ID')) accountIdColumn = true;
        });
        if (accountIdColumn) {
            tableValues.forEach((row, i) => {
                if (isNumeric(row[1])) tableValues[i][1] = "[" + tableValues[i][1] + "]"
            });
        }
        const result = {};
        splitIndexes.forEach((split, index) => {
            let endValue = tableValues.length - 1;
            if (index < splitIndexes.length - 1) {
                endValue = splitIndexes[index + 1].value - 1
            }
            result[split.label] = tableValues.slice(split.value, endValue);
        })
        const labelArr = [];
        splitIndexes.forEach(a => labelArr.push(a.label));
        // add All option
        splitIndexes.unshift({ value: -1, label: 'All' });
        const selectedArrBlock = result[processedOption || splitIndexes[1].label];
        return (<div>
            <br />
            <Select
                defaultValue={splitIndexes[0].label}
                // isMulti
                name="colors"
                options={splitIndexes}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => setProcessedOption(e.label)}
            />
            Filter: <input type="text" value={processedNameFilter} onChange={(e) => setProcessedNameFilter(e.target.value)} />
            {(processedOption !== 'All') ? renderBlock(selectedArrBlock) : splitIndexes.map((e, i) => renderBlock(result[e.label]))}
        </div>)
    }

    function load(dim) {
        if (filteredDim.includes(dim)) {
            if (!data[dim]) {
                setAndfilter(dim);
                return <div>Loading</div>
            }
            else {
                return <Select
                    defaultValue={[]}
                    // isMulti
                    name="colors"
                    options={data[dim]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => AddSelectetdFilter(e, dim)}
                />
            }
        }
    }

    function csvTable() {
        return (
            <div>
                <table className='table tbl'>
                    <thead><tr>{tableRows.map((rows, index) => <th key={index}>{rows}</th>)}</tr></thead>
                    <tbody>{tableValues.map((value, index) => <tr key={index}>{value.map((val, i) => <td key={i}>{val}</td>)}</tr>)}</tbody>
                </table>
            </div>
        );
    }

    return (
        <div style={{ width: '90%', margin: 'auto' }}>
            <div className="row align-items-start" style={{ display: 'inline-flex' }}>
                <div className="col">
                    <label htmlFor="start">From-</label>
                    <input style={{ float: 'right' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    <label htmlFor="end">To:</label>
                    <input style={{ float: 'right' }} type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                </div>
                <div className="col" style={{ display: "grid", marginBottom: '4%' }}>
                    <Checkbox
                        label="Advance"
                        value={showAdvance}
                        onChange={() => setShowAdvance(!showAdvance)}
                    />
                    <Checkbox
                        label="BD accounts(14m.o)"
                        value={getBD}
                        onChange={() => setGetBD(!getBD)}
                    />
                    <Checkbox
                        label="Show IDs"
                        value={showId}
                        onChange={() => setShowId(!showId)}
                    />
                    <Checkbox
                        label="Show API Query"
                        value={showQuery}
                        onChange={() => setShowQuery(!showQuery)}
                    />
                    <Checkbox
                        label="Statistics API"
                        value={statisticsApi}
                        onChange={() => setsStatisticsApi(!statisticsApi)}
                    />
                </div>

            </div>
            {showAdvance && <div className="row align-items-start">
                <div className="col">
                    <h5>Dimensions</h5>
                    {dimensions && dimensions.map(dim => (
                        <div className="form-check" key={dim}>
                            <Checkbox
                                label={dim}
                                value={dim}
                                array={selectedDim}
                                onChange={() => AddSelector(dim)}
                                disabled={dim === 'appVersionId'}
                            />
                        </div>
                    ))}
                </div>
                <div className="col">
                    <h5>Filters</h5>
                    {dimensions && dimensions.map(dim => (
                        <div className="form-check" key={dim}>
                            <Checkbox
                                label={dim}
                                value={dim}
                                array={filteredDim}
                                onChange={() => AddFilter(dim)}
                                disabled={dim === 'appVersionId'}
                            />
                            <div>
                                <div className="card-body">
                                    {load(dim)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {!statisticsApi && <div className="col">
                    <h5>Kpis</h5>
                    {kpis && kpis.map(item => (
                        <div className="form-check" key={item.itemLabel}>
                            <Checkbox
                                label={item.itemLabel}
                                value={item}
                                onChange={() => AddSources(item)}
                            />
                        </div>
                    ))}
                </div>}
                {statisticsApi && <div className="col">
                    <h5>Stats Source</h5>
                    <div className="form-check">
                        <Checkbox
                            label="revenue"
                            value="revenue"
                            onChange={() => setSelectedSource([{ content: "revenue", types: ['revenues'] }])}
                        />
                    </div>
                    <div className="form-check">
                        <Checkbox
                            label="report"
                            value="report"
                            onChange={() => setSelectedSource([{ content: "report", types: ['report'] }])}
                        />
                    </div>
                    <div className="form-check">
                        <Checkbox
                            label="track (user)"
                            value="track"
                            onChange={() => setSelectedSource([{ content: "track", types: ['track'] }])}
                        />
                    </div>
                </div>}
                <div className="col" style={{ display: 'inline-grid' }}>
                    <h5>Others</h5>
                    <Checkbox
                        label="Set Date as Columns"
                        value={dateAsColumns}
                        onChange={() => setDateAsColumns(!dateAsColumns)}
                    />
                    <Checkbox
                        label="JSON format"
                        value={JSONFormat}
                        onChange={() => setJSONFormat(!JSONFormat)}
                    />
                </div>
            </div>}
            {showQuery && <div className="form-group">
                <label htmlFor="usr">Paste Query:</label>
                <input type="text" className="form-control" id="usr" onChange={(e) => setApiQuery(e.target.value)} />
                <br />
                <div className="row" style={{ paddingTop: '1%' }}>
                    <pre>{apiQuery}</pre>
                </div>
            </div>}
            <div style={{margin: '2% 0'}}>
                <button type="button" className="btn btn-primary" disabled={runDisabled} onClick={fetchStats}>Run</button>
                {statisticsApi && <button type="button" style={{ margin: '1%' }} disabled={!data.accountId} className="btn btn-warning" onClick={() => fetchStatsAPI()}>Run Stats API</button>}
                {tableRows && <button type="button" style={{ margin: '1%' }} className="btn btn-secondary" onClick={getCsv}>Download</button>}
            </div>
            {parsedData && <CSVLink
                data={parsedData}
                filename='report.csv'
                className='hidden'
                ref={csvLink}
                target='_blank'
            />}
            {tableRows && tableRows.length > 0 && <Checkbox
                label="Process Result"
                value={processData}
                onChange={() => setProcessData(!processData)}
            />}
            {processData && tableRows.length > 0 && <Checkbox
                label="Show MCM/GAM"
                value={showMCM}
                onChange={() => setShowMCM(!showMCM)}
            />}
            {processData && !loading && analyzeCSVData()}
            {tableRows && !loading && !processData && csvTable()}
            {statisticsApi && JSONRaw && <pre style={{ margin: '2%', background: 'whitesmoke', padding: '1%' }}>{JSON.stringify(JSONRaw, null, 4)}</pre>}
            {loading && (
                <div className="loader-container">
                    <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                    />
                </div>
            )}
        </div>
    )
};

export default Stats