import React, { useState, useEffect, useRef } from 'react';
import Fetch from '../fetch';
import Select from 'react-select';
import { ColorRing } from "react-loader-spinner";
import AuthModal from './AuthModal';
import ModalWrapper from './ModalWrapper';

const C = require('../config');

const today = new Date().toLocaleDateString('en-CA');
const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-CA');

const attrMapper = [
    'dsp_company',
    'country',
    'traffic',
    'inventory',
    'placement',
    'address'
]

const Checkbox = ({ label, value, onChange, disabled, array }) => {
    const arrCheck = (array) ? array.includes(value) : value;
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={array ? arrCheck : undefined} onChange={onChange} disabled={disabled} />
            {' ' + label}
        </label>
    );
};
const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);
function getColorFromPercentage(percentage) {
    // Ensure percentage is within bounds
    percentage = Math.max(-100, Math.min(100, percentage));
    // Convert percentage to a hue value between 0 (red) and 120 (green)
    // -100% maps to 0 (red), 0% maps to 60 (yellow), 100% maps to 120 (green)
    const hue = (percentage + 100) * 0.6;
    // Use a saturation and lightness of 100% for vivid colors
    return `hsl(${hue}, 100%, 50%)`;
}

const Stats = () => {
    const [apiQuery, setApiQuery] = useState(null);
    const [selectedKpi, setSelectedKpi] = useState('ssp_revenue');
    const [selectedDim, setSelectedDim] = useState('company_dsp');
    const [startDate, setStartDate] = useState(sevenDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [tableRows, setTableRows] = useState([]);
    const [tableValues, setTableValues] = useState([]);
    const [dateAsColumns, setDateAsColumns] = useState(true);
    const [processData, setProcessData] = useState(false);
    const [showAdvance, setShowAdvance] = useState(false);
    const [JSONFormat, setJSONFormat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [token, setToken] = useState(getAuth());
    const [processedNameFilter, setProcessedNameFilter] = useState('')
    const [hideGB, setHideGB] = useState(true)
    const [kpiList, setKpiList] = useState([]);
    const [rawData, setRawData] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [activeInfoTab, setActiveInfoTab] = useState('country');
    const [infoModalDate, setInfoModalDate] = useState();
    const [infoModalPub, setInfoModalPub] = useState(null);

    async function fetchStats() {
        setLoading(true);
        const statsResult = await Fetch.post(`${C.projects.core.api + '/report/'}`, { reportUrl: apiQuery });
        FilterData(statsResult)
    }

    useEffect(() => {
        createQuery();
    }, [selectedDim, token, dateAsColumns, JSONFormat, startDate, endDate])

    useEffect(() => {
        FilterData(rawData);
    }, [selectedKpi])

    useEffect(() => {
        modalData ? showExtraInfo(infoModalDate) : null;
    }, [activeInfoTab])

    function FilterData(statsResult) {
        if (!statsResult) return;
        if (statsResult.message && statsResult.message.includes('Token invalid')) {
            setToken(null);
            return
        }

        const statsResultRawData = statsResult.data;
        statsResultRawData.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.date) - new Date(b.date);
        });

        const dspList = [];
        // extract all dsps
        const unique = statsResultRawData.filter((obj, index) =>
            statsResultRawData.findIndex((item) => item[selectedDim] === obj[selectedDim]) === index
        );
        unique.forEach(a => dspList.push(a[selectedDim]))
        const headers = [selectedDim];
        const data = [];

        dspList.forEach((dsp, i) => {
            const filteredDateAsRows = statsResultRawData.filter(row => row[selectedDim] == dsp);
            if (i == 0) filteredDateAsRows.forEach(p => headers.push(p.date))
            let row = [dsp];
            filteredDateAsRows.forEach(p => row.push(p[selectedKpi]))
            data.push(row)
        })
        const kpiArr = [];
        Object.keys(statsResultRawData[0]).map(i => kpiArr.push({ value: i, label: i }))
        setRawData(statsResult)
        setKpiList(kpiArr)
        data.unshift(headers);
        setTableRows(headers)
        setTableValues(data)
        setLoading(false);
    }

    function createQuery() {
        let finalQuery = token + '/adx-report';
        finalQuery += '?attribute[]=' + selectedDim
        finalQuery += '&from=' + startDate + '&to=' + endDate;
        finalQuery += '&limit=3000'
        setApiQuery(finalQuery);
    }

    function basicTable(dataTable) {
        const keys = [
            { name: activeInfoTab, float: true },
            { name: 'dsp_spend', float: true },
            { name: 'dsp_ecpm', float: true },
            { name: 'profit', float: true },
            { name: 'impressions', float: false },
            { name: 'clicks', float: false },
            { name: 'bid_requests', float: false },
        ]

        return <table className='table tbl' style={{display: 'ruby-text'}}>
            <thead>
                <tr>{keys.map(t => <th key={Math.random()}>{t.name}</th>)}</tr>
            </thead>
            <tbody>
                {dataTable.map(t => <tr key={Math.random()}>{keys.map(r => <td key={Math.random()}>{r.float && isNumeric(t[r.name]) ? parseFloat(t[r.name]).toFixed(2).toLocaleString() : t[r.name]}</td>)}</tr>)}
            </tbody>
        </table>
    }

    async function showExtraInfo(infoDate, pub) {
        const selectedPub = pub || infoModalPub;
        let newQuery = apiQuery;
        newQuery = newQuery.replace('company_dsp', 'company_dsp&attribute[]=' + activeInfoTab);
        newQuery = newQuery.replace(startDate, infoDate);
        newQuery = newQuery.replace(endDate, infoDate);
        newQuery += '&filter[company_dsp][]=' + selectedPub;
        const statsResult = await Fetch.post(`${C.projects.core.api + '/report/'}`, { reportUrl: newQuery });
        const res = statsResult.data;
        // sort by dsp_spend
        res.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.dsp_spend - a.dsp_spend;
        });
        setModalData(res.slice(0, 10))
        setInfoModalDate(infoDate)
        setInfoModalPub(selectedPub)
    }

    function renderBlock(data) {
        if (!data) return;
        // extract first two rows (title and dim)
        let subarray = data;

        if (processedNameFilter) subarray = subarray.filter(a => a[0].includes(processedNameFilter))

        if (hideGB) subarray = subarray.filter(a => !a[0].includes('Google Bidding'))

        // sortedBlock
        const sortedArr = subarray.filter(a => a.filter(Number).length).sort((a, b) => {
            if (a.filter(Number).length > 0 && b.filter(Number).length > 0) {
                // filter to get just numbers
                const filteredArrA = a.filter(Number);
                if (filteredArrA.length) {
                    // sum A arr
                    const sumA = filteredArrA.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    // filter to get just numbers
                    const filteredArrB = b.filter(Number);
                    // sum A arr
                    const sumB = filteredArrB.map(el => parseFloat(el)).reduce((a, b) => a + b);
                    return sumB - sumA;
                }
            }
        });

        const columnTotals = ['Column Totals'];
        sortedArr.forEach(e => {
            for (let i = 1; i < sortedArr[0].length; i++) {
                if (columnTotals[i] == undefined) columnTotals.push(0);
                if (isNumeric(e[i])) {
                    columnTotals[i] = parseFloat(columnTotals[i] + parseFloat(e[i]));
                }
            }
        })
        columnTotals.push('')
        sortedArr.unshift(data[0], columnTotals)

        return <div>
            <table className='table tbl'>
                <tbody>
                    {sortedArr.map((value, index) => { //Rows
                        let average = 0;
                        let titleRow = (value[0] == selectedDim);
                        // calculate avg
                        if (value && value.filter(Number).length > 0) {
                            const filteredArr = value.filter(Number);
                            average = filteredArr.map(el => parseFloat(el)).reduce((a, b) => a + b) / filteredArr.length;
                        }
                        //skipping GAM/MCM
                        const totalsColumn = (value[0] === 'Column Totals');

                        let total = 0;

                        return (
                            <tr key={index} className={titleRow || totalsColumn ? 'title' : ''}>
                                {value.map((val, i) => { //Columns
                                    let bg = 'none';
                                    let finalValue = val
                                    const delta = 100 * ((val - average) / Math.abs(average));
                                    if (isNumeric(val)) {
                                        bg = getColorFromPercentage(delta)
                                        finalValue = parseFloat(val).toFixed(2).toLocaleString();
                                        total += parseFloat(val);
                                        return <td className='test' onClick={() => showExtraInfo(sortedArr[0][i], value[0])} style={{ backgroundColor: bg }} key={value[0] + i + total}>{finalValue}</td>;
                                    } else {
                                        // filter per account
                                        return <td className='test' key={value[0] + i + total}>{finalValue}</td>;
                                    }
                                })}
                                {!titleRow && total > 0 ? <td className='test'>{total.toFixed(2)}</td> : ''}
                                {titleRow ? <td className='test'>Row Totals</td> : ''}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    }

    function analyzeCSVData() {
        return (<div>
            kpi:    <Select
                defaultValue={['ssp_revenue']}
                // isMulti
                name="colors"
                options={kpiList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => setSelectedKpi(e.value)}
            />
            Filter: <input type="text" value={processedNameFilter} onChange={(e) => setProcessedNameFilter(e.target.value)} />
            {renderBlock(tableValues)}
        </div>)
    }

    function csvTable() {
        return (
            <div>
                <table className='table tbl'>
                    <thead><tr>{tableRows.map((rows, index) => <th key={index}>{rows}</th>)}</tr></thead>
                    <tbody>{tableValues.map((value, index) => <tr key={index}>{value.map((val, i) => <td key={i}>{val}</td>)}</tr>)}</tbody>
                </table>
            </div>
        );
    }

    function getAuth() {
        const prevUser = sessionStorage.getItem(C.sessionName);
        if (prevUser && prevUser !== 'undefined' && prevUser.includes('rtbToken')) {
            return JSON.parse(prevUser).rtbToken;
        }
        return null;
    }

    function setAuth(token) {
        const prevUser = sessionStorage.getItem(C.sessionName);
        const currentUser = JSON.parse(prevUser);
        currentUser.rtbToken = token;
        sessionStorage.setItem(C.sessionName, JSON.stringify(currentUser));
        setToken(token)
    }

    return (
        <div style={{ width: '90%', padding: '2%', margin: 'auto' }}>
            {!token && <div className='row' style={{ width: '120px', margin: '1% 0 1%' }}><AuthModal url={C.projects.core.api + '/demand'} type="warning" open={false} text="Login with TechBlaze" onLogin={(t) => setAuth(t.token)} /></div>}

            {token && <div>
                <div className="row align-items-start" style={{ display: 'inline-flex' }}>
                    <div className="col">
                        <label htmlFor="start">From-</label>
                        <input style={{ float: 'right' }} type="date" id="start" name="trip-start" value={startDate} min="2018-01-01" max={today} onChange={(e) => setStartDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                        <label htmlFor="end">To:</label>
                        <input style={{ float: 'right' }} type="date" id="end" name="trip-start" value={endDate} min="2018-01-01" max={today} onChange={(e) => setEndDate(new Date(e.target.value).toLocaleDateString('en-CA'))} />
                    </div>
                    <div className="col" style={{ display: "grid" }}>
                        <Checkbox
                            label="Advance"
                            value={showAdvance}
                            onChange={() => setShowAdvance(!showAdvance)}
                        />
                        <Checkbox
                            label="Show API Query"
                            value={showQuery}
                            onChange={() => setShowQuery(!showQuery)}
                        />
                        <Checkbox
                            label="Include GBidding"
                            value={hideGB}
                            onChange={() => setHideGB(!hideGB)}
                        />
                    </div>

                </div>
                {showAdvance && <div className="row align-items-start">
                    <div className="col">
                        <h5>Group by</h5>
                        {attrMapper.map(dim => (
                            <div className="form-check" key={dim}>
                                <Checkbox
                                    label={dim}
                                    value={dim}
                                    array={selectedDim}
                                    onChange={() => AddSelector(dim)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="col" style={{ display: 'inline-grid' }}>
                        <h5>Others</h5>
                        <Checkbox
                            label="Set Date as Columns"
                            value={dateAsColumns}
                            onChange={() => setDateAsColumns(!dateAsColumns)}
                        />
                        <Checkbox
                            label="JSON format"
                            value={JSONFormat}
                            onChange={() => setJSONFormat(!JSONFormat)}
                        />
                    </div>
                </div>}
                <br />
                {showQuery && <div className="form-group">
                    <label htmlFor="usr">Paste Query:</label>
                    <input type="text" className="form-control" id="usr" onChange={(e) => setApiQuery(e.target.value)} />
                    <br />
                    <div className="row" style={{ paddingTop: '1%' }}>
                        <pre>{C.projects.rtbApi.api + '/' + apiQuery}</pre>
                    </div>
                </div>}
                <div>
                    <button type="button" className="btn btn-primary" onClick={fetchStats}>Run</button>
                </div>
                <br />
                {tableRows && tableRows.length > 0 && <Checkbox
                    label="Process Result"
                    value={processData}
                    onChange={() => setProcessData(!processData)}
                />}
                {processData && !loading && analyzeCSVData()}
                <br />
                {modalData && <ModalWrapper open={true} title="Response" buttonTitle='Response' onClose={() => setModalData(null)}>
                    <div style={{ overflow: 'auto'}}>
                        <ul className="nav nav-tabs">
                            <li className={activeInfoTab === 'country' ? "nav-item" : ''} onClick={() => setActiveInfoTab('country')}><a className="nav-link" href="#">Country</a></li>
                            <li className={activeInfoTab === 'ad_format' ? "nav-item" : ''} onClick={() => setActiveInfoTab('ad_format')}><a className="nav-link" href="#">ad_format</a></li>
                            <li className={activeInfoTab === 'domain' ? "nav-item" : ''} onClick={() => setActiveInfoTab('domain')}><a className="nav-link" href="#">domain</a></li>
                        </ul>
                        <br />
                        Top 10 Stats for {infoModalPub} on {infoModalDate} by {activeInfoTab}
                        {activeInfoTab && basicTable(modalData)}
                    </div>
                </ModalWrapper>}
                {tableRows && !loading && !processData && csvTable()}
                {loading && (
                    <div className="loader-container">
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
                        />
                    </div>
                )}

            </div>}


        </div>
    )
};

export default Stats