import React, { useState, useEffect } from 'react';
import { EntryModal, SignatureModal, ImageModal } from './Modals';

const createTable = (newExp, isSubView, signFn, editRowFn, deleteRowFn) => {
    const { name, purpose, department, entries, sign, supervisorSign, supervisorAssigned, supervisorDate, email, draft } = newExp;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImagesArr, setModalImagesArr] = useState(null);

    const openModal = (imagesArr) => {
        setModalImagesArr(imagesArr);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImagesArr(null);
    };

    const getDate = (obj) => {
        let d = new Date();
        if (obj._id) {
            const _int = parseInt(obj._id.substring(0, 8), 16);
            d = new Date(_int * 1000);
        }
        return d.toLocaleDateString('en-CA', { timeZone: 'UTC' })
    }

    function drawOrRender({item, id, condition, onClickFn}) {
        if (item) {
            if (item.includes('base64')) {
                return (<img
                    src={item}
                    id={id}
                    alt="my signature"
                    style={{
                        display: "block",
                        margin: "0 auto",
                        width: "150px"
                    }} />)
            } else {
                return (<p className='signature1' id="Sign">{item}</p>)
            }
        } else {
            if (condition()) {
                return (<button type="button" style={{ margin: '2% 0' }} className="btn btn-warning" data-mdb-dismiss="modal" onClick={() => onClickFn()}>Sign Document</button>)
            }
            else return null;
        }
    }

    return (
        <div className="ritz grid-container" dir="ltr" id="pdf">
            <span className="navbar-brand mb-20 h1" style={{ float: 'left' }}>
                <img src="https://i.postimg.cc/6pPC33Tv/logo-267x69.jpg" alt="gravite logo" width="120" height="31" />
            </span>
            {modalOpen && <ImageModal show={modalOpen} target={modalImagesArr} isSubView={isSubView} onClose={closeModal} />}
            <table className="waffle no-grid" cellSpacing={0} cellPadding={0}>
                <tbody>
                    <tr style={{ height: 52 }}>
                        <td className="s2" colSpan={6}>
                            EXPENSE REPORT
                        </td>
                    </tr>
                    <tr style={{ height: 16 }} />

                    <tr style={{ height: 42 }}>
                        <td className="s4">REQUESTER NAME</td>
                        <td className="s5" dir="ltr" > {name} </td>
                        <td className="s4">PURPOSE</td>
                        <td className="s5" > {purpose} </td>
                        <td className="s4">DEPARTMENT</td>
                        <td className="s5" dir="ltr"> {department}</td>
                    </tr>
                    <tr style={{ height: 25 }} />
                    <tr style={{ height: 36 }}>
                        <td className="s6">Item Number </td>
                        <td className="s6">Category</td>
                        <td className="s6">Description</td>
                        <td className="s6">Date</td>
                        <td className="s6">Notes</td>
                        <td className="s6" dir="ltr">
                            Amount
                        </td>
                    </tr>
                    {!entries.length ? <tr style={{ height: 29 }}>

                    </tr> : entries.map((en, i) => (
                        <tr key={i} style={{ height: 29 }}>
                            <td className="s7">{i + 1}</td>
                            <td className="s7" dir="ltr">
                                {en.category}
                            </td>
                            <td className="s7" dir="ltr"> {en.description}</td>
                            <td className="s7" dir="ltr">  {en.date}</td>
                            <td className="s7" dir="ltr">  {en.participants}</td>
                            <td className="s7" dir="ltr" >{en.amount} {en.currency}</td>
                            <td className="s7 notPrint" dir="ltr">
                                {(!isSubView || newExp.draft) ? <>
                                    <button type="button" style={{ marginBottom: '2%', scale: '0.7' }} className="btn btn-info" onClick={() => editRowFn(en)}>
                                        <i className="bi bi-pencil-square"></i>
                                    </button>
                                    <button type="button" style={{ marginBottom: '2%', scale: '0.7' }} className="btn btn-danger" onClick={() => deleteRowFn(en)}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </> : null}

                                {en?.images?.length ? (<a href="#" onClick={() => openModal(en.images)}><i className="bi bi-eye"></i>
                                </a>) : ('No Attch.')}
                            </td>
                        </tr>
                    ))}

                    <tr style={{ height: 53 }}>
                        <td className="s10" />
                        <td className="s10" />
                        <td className="s11" />
                        <td className="s12">SUMME</td>
                        <td className="s12" />
                        <td className="s12">{entries.reduce((accum, item) => accum + item.amount, 0)} {entries[0]?.currency || 'EUR'}</td>
                    </tr>

                    <tr style={{ height: 44 }}>
                        <td className="s14">SUPERVISOR DATE:</td>
                        <td className="s15" colSpan={2} > {supervisorDate ? (new Date(supervisorDate).toLocaleDateString('en-CA', { timeZone: 'UTC' })) : null}</td>
                        <td className="s14">EMPLOYEE DATE:</td>
                        <td className="s15" colSpan={2} > {getDate(newExp)}</td>
                    </tr>
                    <tr style={{ height: 57 }}>
                        <td className="s16">
                            SUPERVISOR <br />
                            SIGNATURE:
                        </td>
                        <td className="s15" colSpan={2} > {drawOrRender({
                            item: supervisorSign,
                            id: 'supervisorSignature',
                            condition: () => (isSubView && supervisorAssigned && supervisorAssigned === email),
                            onClickFn: () => signFn()
                        })}</td>
                        <td className="s16">
                            EMPLOYEE <br />
                            SIGNATURE:
                        </td>
                        <td className="s15" colSpan={2} > {drawOrRender({
                            item: sign,
                            id: 'employeeSignature',
                            condition: () => (!isSubView || draft),
                            onClickFn: () => signFn()
                        })}</td>
                    </tr>
                </tbody>
            </table></div>)
};

const Expense = (props) => {
    //   const temp = Template('John', 'intern', 'john@gravite.net', '+123456789', 'in');
    const [name, setName] = useState('');
    const [purpose, setPurpose] = useState('');
    const [department, setDept] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [entries, setEntries] = useState([])
    const [supervisorAssigned, setsupervisorAssigned] = useState(null)
    const [showSignatureModal, setShowSignatureModal] = useState(false)
    const [sign, setSign] = useState(null); // create a state that will contain our image url
    const [supervisorSign, setSupervisorSign] = useState(null); // create a state that will contain our image url
    const [supervisorDate, setSupervisorDate] = useState(null);
    const [attachedPdf, setAttachedPdf] = useState(null);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (props.selected) reload(props.selected)
    }, [])

    useEffect(() => {
        if (props.selected) {
            reload(props.selected)
        } else {
            reload({
                name: '',
                purpose: '',
                department: '',
                entries: [],
                images: [],
                signature: null,
                supervisorDate: null,
                supervisorSignature: null
            })
        }
    }, [props.selected])

    function reload(item) {
        setName(item.name)
        setPurpose(item.purpose)
        setDept(item.department)
        setEntries(item.entries)
        setSign(item.signature)
        setSupervisorSign(item.supervisorSignature)
        setSupervisorDate(item.supervisorDate)
        setsupervisorAssigned(item.supervisorAssigned)
    }

    const onModalClose = (newEntry, edit) => {
        if (edit) {
            // edit mode
            const enIndex = entries.findIndex((en) => en.id == newEntry.id);
            if (enIndex !== -1) {
                const newEntries = [...entries];
                newEntries[enIndex] = newEntry
                setEntries(newEntries);
            }
        } else {
            setEntries([...entries, newEntry]);
        }
        setShowModal(false)
    }

    const onSignatureModalClose = (image) => {
        if (props.view === 'supervisor') {
            setSupervisorSign(image);
        } else {
            setSign(image);
        }
        setShowSignatureModal(false)
    }

    const onFinish = async (isDraft) => {
        await props.generatePDF(name, entries, true, props.view, (a) => setAttachedPdf(a));

        const objToSend = {
            name,
            purpose,
            department,
            signature: sign,
            supervisorSignature: supervisorSign
        }

        if (props.onDraftOrSupervisor) {
            let toUpdate;
            if (props.view === 'supervisor') {
                toUpdate = {
                    supervisorDate: new Date().toString(),
                };
            }
            if (props.view === 'draft') {
                toUpdate = {
                    _id: props.selected._id,
                    id: props.selected.id,
                    draft: isDraft || false,
                    email: props.selected.email,
                    entries
                }
            }
            if (attachedPdf) toUpdate.attachedPdf = attachedPdf;
            props.onDraftOrSupervisor({ ...objToSend, ...toUpdate });
            return;
        }
        const id = Math.random().toString(36).slice(2);
        const formData = new FormData();

        entries.forEach((entry, entryIndex) => {
            if (entry?.images?.length) {
                entry.images.forEach((image, index) => {
                    const extension = image.name.split('.').pop();
                    const customFileName = `${id}_${entryIndex}_${index}.${extension}`;
                    const renamedFile = new File([image], customFileName, { type: image.type });
                    formData.append(`uploadExpense_${entryIndex}_${index}`, renamedFile);
                });
            }
        });

        const newObj = {
            id,
            draft: isDraft || false,
            email: props?.user?.email,
            entries: entries.map((entry, entryIndex) => ({
                ...entry,
                images: entry.images?.map((_, index) => `${id}_${entryIndex}_${index}`)
            })),
        }
        formData.append('expenseObject', JSON.stringify({ ...objToSend, ...newObj }));
        if (attachedPdf) formData.append('attachedPdf', attachedPdf);
        reload({
            name: '',
            purpose: '',
            department: '',
            entries: [],
            images: [],
            signature: null,
            supervisorDate: null,
            supervisorSignature: null
        })
        props.onCreate(id, formData)
    };

    const editRow = (en) => {
        setSelected(en)
        setShowModal(true)
    }

    const delRow = (en) => {
        const newEntriesArr = entries.filter(it => it.description !== en.description);
        setEntries(newEntriesArr);
    }

    function isDisabled() {
        if (props.view === 'supervisor') {
            return !supervisorSign;
        } else {
            return !name || !purpose || !department || !sign || !props?.user?.email;
        }
    }

    function checkConditions() {
        let res = false;
        if (!props.selected) { // new expense
            if (sign) res = true;
        } else {
            if (props.selected.draft) res = true;
            if (props.view === 'supervisor' && supervisorAssigned && supervisorAssigned === props.user.email) res = true;
        }
        return res;
    }

    const expenseTableData = {
        _id: props.selected?._id || null,
        name,
        purpose,
        department,
        entries,
        sign,
        supervisorSign,
        supervisorDate,
        supervisorAssigned,
        email: props.user.email,
        draft: props.selected?.draft
    }

    return (
        <div className="container" id='expenseReport' style={{ marginTop: '1rem' }}>
            {(!props.view || props.view === 'draft' || props.view === 'admin') ? <>
                Please fil out the form to create a unique expense report
                <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                    <input type="email" required className="form-control" id="exampleFormControlInput1" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                    <input type="email" required className="form-control" id="exampleFormControlInput1" placeholder="purpose" value={purpose} onChange={e => setPurpose(e.target.value)} />
                </div>

                <div className="row input-group" style={{ width: '20%', padding: '1%' }}>
                    <label className="form-check-label" htmlFor="bannerSelect">
                        Department
                    </label>
                    <select className="form-select" id="bannerSelect" defaultValue={department} onChange={e => setDept(e.target.value)}>
                        <option value='Finance'>Finance</option>
                        <option value="Human Resources">Human Resources</option>
                        <option value="Management">Management</option>
                        <option value="KAM">KAM</option>
                        <option value="Business Development">Business Development</option>
                        <option value="Development">Development</option>
                        <option value="Marketing">Marketing</option>
                    </select>
                </div>

                <button type="button" style={{ marginBottom: '2%' }} className="btn btn-secondary" data-mdb-dismiss="modal" onClick={() => editRow(null)}>
                    + Add new Expense entry
                </button>
            </> : null}

            {showModal && <EntryModal show={showModal} view={props.view} selected={selected} onConfirm={onModalClose} onCancel={() => setShowModal(false)} />}
            <br />

            {showSignatureModal && <SignatureModal show={showSignatureModal} onConfirm={(image) => onSignatureModalClose(image)} name={expenseTableData.name} onCancel={() => setShowSignatureModal(false)} />}

            <div className="row" id="test" style={{ margin: "4rem 0" }}>
                <div id="toDownload">
                    {createTable(expenseTableData, props.view, () => setShowSignatureModal(true), editRow, delRow)}
                </div>
                <div id="wrapper" />
            </div>

            <div className="btn-group btn-group-lg" style={{ width: '100%', marginBottom: '200px' }} role="group" >
                <button type="button" className="btn btn-primary" data-mdb-dismiss="modal" onClick={() => props.generatePDF(name, entries, false, props.view)} >
                    Download
                </button>
                {(!props.view || props.view === 'draft') ? <button type="button" className="btn btn-secondary" data-mdb-dismiss="modal" onClick={() => onFinish(true)}>
                    Save as draft
                </button> : null}
                {checkConditions() ? <button type="button" className="btn btn-success" data-mdb-dismiss="modal" onClick={() => onFinish(false)} disabled={isDisabled()}>
                    {props.view === 'supervisor' ? 'Close' : 'Send to travel@gravite.net'}
                </button> : null}
            </div>
        </div >
    );
}

export default Expense;
