import React, { useState, useEffect } from 'react';
import Pipeline from './Pipeline';
import Tests from './Tests';
import Debug from './Debug';
import AuthModal from './AuthModal';
import OAuth from './OAuth';
import WhitePages from './WhitePages';
import DemandPages from './DemandPages';
import Expenses from './Expenses';
import EmailTemplate from './EmailTemplate';
import Admin from './Admin';
import C from '../config';

// https://icons.getbootstrap.com/

const sectionList = [
    {
        icon: 'bi bi-graph-up-arrow',
        title: 'White Pages',
        main: true,
        render: (user) => <WhitePages user={user} />
    },
    {
        icon: 'bi bi-clipboard-data',
        title: 'Demand Pages',
        main: true,
        render: (user) => <DemandPages user={user} />
    },
    {
        icon: 'bi bi-file-earmark-medical',
        title: 'Expense Report',
        main: true,
        render: (user) => <Expenses/>
    },
    {
        icon: 'bi bi-envelope-paper-fill',
        title: 'Email',
        admin: true,
        main: true,
        render: (user) => <EmailTemplate user={user} />,
    },
    {
        icon: 'bi bi-key-fill',
        title: 'Admin',
        admin: true,
        main: true,
        render: (user) => <Admin user={user} />,
    },
    {
        icon: 'bi bi-boxes',
        title: 'Pipeline',
        admin: true,
        main: true,
        render: (user) => <Pipeline user={user} />,
    },
    {
        icon: 'bi bi-diagram-2-fill',
        title: 'Test',
        admin: true,
        main: true,
        render: (user) => <Tests user={user} />
    },
    {
        icon: 'bi bi-bug',
        title: 'Debug',
        admin: true,
        main: true,
        render: (user) => <Debug user={user} />
    },
    {
        icon: 'bi bi-key',
        title: 'OAuh',
        admin: true,
        main: true,
        render: (user) => <OAuth user={user} />
    },
    {
        icon: 'bi bi-hdd-network',
        title: 'Swagger',
        admin: true,
        collection: (sectionList) => sectionList.filter(s => s.swagger),
        main: true,
    },
    {
        icon: 'bi bi-link',
        title: 'Links',
        admin: true,
        collection: (sectionList) => sectionList.filter(s => s.link),
        main: true,
    },
    {
        icon: 'bi bi-pie-chart-fill',
        title: 'MAYO Plot',
        admin: true,
        swagger: true,
        render: C.projects.mayoPlot.dev,
        auth: true,
    },
    {
        icon: 'bi bi-bar-chart-steps',
        title: 'Task Scheduler',
        admin: true,
        swagger: true,
        render: C.projects.taskScheduler.prod,
        auth: true,
    },
    {
        icon: 'bi bi-envelope-check',
        title: 'Notification Service',
        admin: true,
        swagger: true,
        render: C.projects.notificationService.dev,
        auth: true,
    },
    {
        icon: 'bi bi-database-up',
        title: 'DB Manager',
        admin: true,
        link: C.projects.db
    },
    {
        icon: 'bi bi-view-stacked',
        title: 'Portainer',
        admin: true,
        link: C.projects.portainer.dev
    },
    {
        icon: 'bi bi-microsoft-teams',
        title: 'Core Overview',
        admin: true,
        link: C.projects.coreOverview
    }
]

const Frame = (props) => {
    const [loadingIframe, setLoadingIframe] = useState(true);
    const [env, setEnv] = useState(null);
    const [token, setToken] = useState(true);
    const [target, setTarget] = useState(props.url);

    const envChecker = (val) => {
        setEnv(val);
        if (target.includes('scheduler') && val !== 'test') {
            setTarget(C.projects.taskScheduler.prod)
        }
    };

    useEffect(() => {
        setEnv(null);
        setTarget(props.url);
    }, [props.url]);

    return (
        <>
            {props.auth && C.projects && (<div style={{ padding: "1%" }}>
                <select className="form-select small" onChange={(e) => envChecker(e.target.value)} aria-label="Default select example">
                    <option value={''} >-- Select --</option>
                    {Object.keys(C.projects.dashboard).map((item) => <option key={item} value={C.projects.dashboard[item].backend}>{item}</option>)}
                </select>
                <AuthModal url={env + '/auth'} onLogin={(usr) => setToken(usr.token)} />
                {token && <pre className="elips" style={{ maxWidth: '800px', margin: "0 2%", display: 'inline-block' }}>
                    {token}
                </pre>}
            </div>
            )}
            <iframe
                style={{
                    width: "100%",
                    height: "100%",
                }}
                src={target}
                onLoad={() => setLoadingIframe(false)}
            ></iframe>
            {loadingIframe ? <p> Loading iframe</p> : null}
        </>
    );
}

const Nav = (props) => {
    const { user, onLogin, onLogout } = props;
    return (
        <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">
                    <a href="/" style={{ marginRight: '1%' }}>
                        <img src="https://i.postimg.cc/6pPC33Tv/logo-267x69.jpg" alt="gravite logo" width="120" height="31" />
                    </a> Core Admin Tool (CAT)
                </span>
                <div style={{ float: 'right', display: 'inline-flex' }}>
                    {!user && <AuthModal user={user} open={true} keepButton={true} onLogin={onLogin} onLogout={onLogout} />}
                    {user ? <button type="button" className="btn btn-link" onClick={onLogout} style={{ marginRight: '1%' }}>Logout</button> : ''}
                </div>
            </div>
        </nav>
    );
}

const Menu = (props) => {
    const { user } = props;
    const { admin } = user;
    const [section, setSection] = useState();

    const selectedList = getSelectedList();

    function getSelectedList() {
        if (admin) return sectionList.filter(s => s.main)
        else return sectionList.filter(s => !s.admin && s.main);
    }

    function getSection() {
        if (section.link) {
            window.open(section.link, '_blank'); 
            setSection(null);
        }
        if (typeof section.render === 'function') {
            return section.render(user);
        } else {
            return <Frame auth={section.auth} url={section.render} />
        }
    }

    const Grid = (props) => {
        return (
            <div className="container">
                <div className="row">
                    {props.list.map((item, index) => (
                        <div key={index} className="col-md-4 col-sm-6 mb-4 gridItem" style={{ cursor: 'pointer' }}>
                            <div className="card">
                                <div className="card-body" onClick={() => setSection(item)}>
                                    <i className={item.icon + ' fs-1'} />
                                    <h5 className="card-title">{item.title}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        // vertical Menu
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div className="col-sm-auto bg-light sticky-top menu">
                    <div className="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
                        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                            {selectedList.map(sec => (<li key={sec.icon} onClick={() => setSection(sec)}>
                                <a href={"#"} className={`nav-link ${section && (section.title === sec.title) ? 'selected' : ''}`} title={sec.title} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                                    <i className={sec.icon + ' fs-1'} />
                                    <p>{sec.title}</p>
                                </a>
                            </li>))}
                        </ul>
                    </div>
                </div>
                <div className="col-sm p-3 min-vh-100">
                    {!section && <Grid list={selectedList} />}
                    {section && section.collection && <Grid list={section.collection(sectionList)} />}
                    {section && !section.collection && getSection()}
                </div>
            </div>
        </div>
    )
}

const Wrapper = props => {
    const { user, onLogin, onLogout } = props;
    return (
        <>
            <Nav user={user} onLogin={(usr) => onLogin(usr)} onLogout={() => onLogout()} />
            {user && <Menu user={user} />}
        </>
    );
}

export default Wrapper
