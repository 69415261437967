import React, { useState, useEffect } from 'react';
import C from '../config';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Main from "./Main";
import Login from "./AuthModal";
import DemandPages from './DemandPages';
import Expenses from './Expenses';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const prevUser = sessionStorage.getItem(C.sessionName);
        if (prevUser && prevUser !== 'undefined' && prevUser.includes('jwt')) setUser(JSON.parse(prevUser));
    }, []);

    function onLogin(usr) {
        if (usr?.pipeline?.jwt || usr?.dashboard?.token) {
            sessionStorage.setItem(C.sessionName, JSON.stringify(usr));
            setUser(usr);
        }
    }

    function onLogout() {
        sessionStorage.removeItem(C.sessionName);
        setUser(null);
    }

    return <Main user={user} onLogin={(usr) => onLogin(usr)} onLogout={() => onLogout()} />;
}

const Wrapper = (props) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            // You now have access to `window`
            window.toast = (a) => toast.success(a);
        }

    }, []);

    return <div>
        <ToastContainer />
        {props.section}
    </div>
}

const RouterWrapper = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<Wrapper section={<Dashboard />} />}
                ></Route>
                <Route
                    path="/login"
                    element={<Login open={true} />}
                ></Route>
                <Route
                    path="/demand"
                    element={<Wrapper section={<DemandPages />} />}
                ></Route>
                <Route
                    path="/Expense"
                    element={<Wrapper section={<Expenses />} />}
                ></Route>
            </Routes>
        </Router >
    );
}


export default RouterWrapper
