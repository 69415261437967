import React, { useState, useEffect } from 'react';
import Fetch from '../fetch';
const C = require('../config');

const EmailTemplate = (props) => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const [template, setTemplate] = useState();
    const [isJson, setIsJson] = useState(false);
    const [body, setBody] = useState();
    const [response, setResponse] = useState();
    const [properties, setProperties] = useState();

    const get = async () => {
        const requestJson = await Fetch.get(C.projects.core.api + '/email/templates');
        setData(requestJson.data);
    };

    const send = async () => {
        const toSend = {
            email: props.user.email,
            template: template,
            body: body || {},
            isJson
        }
        const sendRes = await Fetch.post(C.projects.core.api + '/email/test', toSend);
        setResponse(sendRes)
    }

    useEffect(() => {
        get();
    }, []);

    function analyze(temp) {
        if (temp.includes('<style')) {
            setProperties(['html']);
            setBody('Paste your HTML code...');
            setIsJson(false);
            return;
        }
        const regex = /\{([^}]+)\}/g;
        let matches;
        const results = [];
        
        while ((matches = regex.exec(temp)) !== null) {
          results.push(matches[1]);
        }
        const res = {};
        results.forEach(a => res[a] = '')

        setIsJson(results.length);
        setProperties(results);
        setBody(JSON.stringify(res, undefined, 4));
    }

    return (
        <>
            <div className="App tableFixHead">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th style={{ width: '35%' }}>Template name</th>
                            <th>Contains</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data ? Object.keys(data).map(el => (
                            <tr key={el} style={{ height: '3em' }} onClick={() => {
                                setTemplate(el);
                                setSelected(atob(data[el]))
                                analyze(atob(data[el]))
                            }} className={el === template ? 'activeRow': ''}>
                                <td>{el}</td>
                                <td>{atob(data[el])}</td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>

                {selected ? <div>
                    <h4> Template </h4>
                    <pre>{selected}</pre>
                </div> : null}
                <h4> Try it out </h4>
                {response && <pre>{JSON.stringify(response, null, 4)}</pre>}
                <p>(Will be sent to your email)</p>
                <p><b>Template selected: </b>{template}</p>
                {properties && properties.length ? <div className="mb-3" style={{ margin: '1%' }}>
                    <h6>Insert Email Params</h6>
                    <div className="row align-items-start">
                        <textarea className="col form-control" value={body} onChange={(e) => setBody(e.target.value)} style={{ margin: '0 1%', display: 'inline-block', height: '500px', width: '40%' }} id="exampleFormControlTextarea1" rows="3"></textarea>
                        <div className="col" dangerouslySetInnerHTML={{ __html: body }} style={{ display: 'inline-block', width: '40%', height: '500px' }} id="exampleFormControlTextarea1" rows="3" />
                    </div>
                </div> : null}

                <button type="button" className="btn btn-warning" onClick={send}>
                    Send test email
                </button>
            </div>
        </>
    )
}

export default EmailTemplate